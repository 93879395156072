<template>
  <div class="my-outbox">
    <div class="my-inbox flex" ref="box" :style="marqueeStyle">
      <div
        class="my-list"
        v-for="(item, index) in logoList"
        :key="index"
        @mouseenter="toPause(index)"
        @mouseleave="toStart(index)"
      >
        <img v-lazy="item.activeIcon" class="icon" />
        <!-- <img
          v-lazy="item.activeIcon"
          class="icon animate__animated"
          :class="
            item.mouse && item.mouse == true
              ? 'animate__fadeIn'
              : 'animate__fadeOut'
          "
        /> -->
      </div>
      <div
        class="my-list"
        v-for="(item, index) in logoList"
        :key="(index + 1) * 100"
        @mouseenter="toPause(index)"
        @mouseleave="toStart(index)"
      >
        <img v-lazy="item.activeIcon" class="icon" />
        <!-- <img
          v-lazy="item.activeIcon"
          class="icon animate__animated"
          :class="
            item.mouse && item.mouse == true
              ? 'animate__fadeIn'
              : 'animate__fadeOut'
          "
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my-marquee-left",
  props: {
    sendVal: Array,
    pauseFlag: Boolean,
  },
  data() {
    return {
      initLeft: 0,
      animateTarget: null,
      logoList: this.sendVal,
      marqueeStyle: {
        transform: "translateX(0px)",
      },
    };
  },
  mounted: function () {
    this.animateTarget = this.$refs.box;
    requestAnimationFrame(this.animateFn);
  },
  methods: {
    animateFn() {
      this.initLeft++;
      if (this.initLeft >= this.animateTarget.offsetWidth / 2) {
        this.initLeft = 0;
      }
      this.marqueeStyle.transform = " translateX(-" + this.initLeft + "px)";
      // this.animateTarget.style =
      //   "transform: translateX(-" + this.initLeft + "px)";
      if (!this.pauseFlag) {
        requestAnimationFrame(this.animateFn);
      }
    },
    toPause(index) {
      this.logoList[index].mouse = true;
      // this.$parent.updatePause(true);
    },
    toStart(index) {
      this.logoList[index].mouse = false;
      // this.$parent.updatePause(false);
    },
  },
  watch: {
    pauseFlag(val) {
      if (val == false) {
        requestAnimationFrame(this.animateFn);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-outbox {
  overflow: hidden;
  height: 150px;
  position: relative;
  width: 100%;
  .my-inbox {
    position: absolute;
    font-size: 0;
    min-width: 100%;

    .my-list {
      height: 130px;
      width: 260px;
      margin-right: 20px;
      position: relative;
      display: table;
      .icon {
        height: 130px;
        width: 260px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>   