<template>
  <div class="home">
    <div class="block-banner">
      <el-carousel arrow="never" trigger="click">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 公司介绍 -->
    <div class="flex w100 ovh" ref="introduceBox">
      <div
        v-for="(item, index) in produceList"
        :key="index"
        class="pro-item flex-v flex-align-center"
        :class="item.mouse == true ? 'bg-white' : ''"
        @mouseenter="item.mouse = true"
        @mouseleave="item.mouse = false"
      >
        <img
          v-lazy="item.bg"
          class="pro-bg animate__animated"
          :hidden="item.mouse == -1"
          :class="
            item.mouse == true ? 'animate__fadeInUp' : 'animate__fadeOutDown'
          "
        />
        <img
          v-lazy="item.icon"
          class="pro-icon animate__animated"
          :class="
            item.mouse == -1
              ? ''
              : item.mouse == true
              ? 'animate__fadeOutUp'
              : 'animate__fadeInDown'
          "
        />
        <div
          class="flex-v animate__animated flex-align-center"
          :class="
            item.mouse == -1
              ? ''
              : item.mouse == true
              ? 'animate__fadeInUp mtm70'
              : 'animate__fadeInDown'
          "
        >
          <div class="bold fz24 transi-up delay-03">
            {{ item.title }}
          </div>
          <div class="c-cac mt5 uppercase transi-up delay-06">
            {{ item.eng }}
          </div>
          <div class="c-666 mt35 fz16 pre lh30 transi-up delay-09 w100" style="text-align: center;">
            {{ item.desc }}
          </div>
        </div>
        <router-link :to="{ name: item.path }" target="_blank">
          <div
            class="animate__animated more-active pointer"
            :class="item.mouse == true ? 'animate__fadeIn' : 'animate__fadeOut'"
            v-show="item.mouse == true"
          >
            了解更多
          </div>
        </router-link>

        <div
          class="animate__animated more-btn transi-up"
          :class="
            item.mouse == -1
              ? ''
              : item.mouse == true
              ? 'animate__fadeOut'
              : 'animate__fadeIn'
          "
          v-show="item.mouse != true"
        >
          了解更多
        </div>
      </div>
    </div>
    <!-- 产品分类 -->
    <div
      class="product-bg-box flex-v flex-align-center"
      ref="productBgBox"
      :class="showProType ? '' : 'no-display'"
    >
      <img v-lazy="productBg" class="product-bg" :style="productBgScroll" />
      <div class="fz32 bold mt42 z0">产品中心</div>
      <div class="fz16 mt8 uppercase c-cac z0">products CENTER</div>
      <div class="z0 color-line mt8"></div>
      <div class="flex w100 flex-nowrap z0 mt62 type-list">
        <div
          class="flex-v flex-1 flex-align-center type-item pointer"
          v-for="(item, index) in productTypeList"
          :key="index"
          @mouseenter="item.mouse = true"
          @mouseleave="item.mouse = false"
          @click="activeProType = item"
        >
          <img
            :src="activeBg"
            class="active-bg"
            :class="
              activeProType.channelId == item.channelId
                ? 'active-bg-show'
                : item.mouse == true
                ? 'active-bg-show'
                : item.mouse == false
                ? 'active-bg-none'
                : 'active-bg-no'
            "
          />

          <div class="fz18 mt5 transi-up delay-03">{{ item.channelName }}</div>
          <div class="fz50 mt10 bold transi-up delay-06">
            {{ item.cChildNum }}
          </div>
        </div>
      </div>
    </div>
    <!-- 产品列表 -->
    <div class="product-box flex-v flex-align-center">
      <div class="flex flex-wrap mw100">
        <div
          v-for="(item, index) in activeProType.cProductBanks"
          :key="index"
          class="product-item flex-v flex-align-center pointer"
        >
          <img v-lazy="item.proImg" class="pro-item-icon mt42" />
          <div class="fz18 mt30">{{ item.proName }}</div>
          <!-- <div class="fz14 mt10 c-999">{{ item.proIntro }}</div> -->
          <router-link
            :to="{
              name: 'productCenter',
              query: {
                typeId: activeProType.channelId,
                productId: item.proId,
                name: item.proName,
              },
            }"
            target="_blank"
          >
            <div
              class="pro-item-bg pro-item-bg-active flex-v flex-align-center flex-pack-center pointer"
            >
              <img v-lazy="detailPic" class="pro-detail-icon" />
              <div class="c-white fz24 mt20">{{ item.proName }}</div>
              <div class="c-white fz16 mt10 w100 pl20 pr20">
                {{ item.proIntro }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="mt30 relative more-btn-box"
        @mouseenter="showProMore = true"
        @mouseleave="showProMore = false"
      >
        <div
          class="animate__animated more-btn flex flex-align-center flex-pack-center fz16 pointer"
          v-show="showProMore !== true"
        >
          更 多
        </div>
        <router-link
          :to="{
            name: 'productCenter',
            query: {
              typeId: activeProType.channelId,
              name: activeProType.channelName,
            },
          }"
          target="_blank"
        >
          <div
            class="animate__animated more-btn-active flex flex-align-center flex-pack-center c-white fz16 pointer"
            :class="
              showProMore == true ? 'animate__fadeIn' : 'animate__fadeOut'
            "
          >
            更 多
          </div>
        </router-link>
      </div>
    </div>
    <!-- 解决方案 -->
    <div
      class="flex solution-box"
      :class="showSolution ? '' : 'no-display'"
      ref="solutionBox"
    >
      <img
        :src="solutionList.length > 0 ? solutionList[oldSolution].img : ''"
        class="old-solution-icon"
      />
      <img
        :src="solutionList.length > 0 ? solutionList[activeSolution].img : ''"
        class="solution-icon animate__animated"
        :class="
          solPicDirection == false
            ? 'animate__fadeInRight'
            : solPicDirection == true
            ? 'animate__fadeInLeft'
            : ''
        "
      />
      <div class="flex-v flex-1 solution-content">
        <div class="flex w100">
          <div class="flex-v flex-1">
            <div class="fz36 bold mt42 transi-up delay-03">
              {{
                solutionList.length > 0
                  ? solutionList[activeSolution].articleTitle
                  : ""
              }}
            </div>
            <div class="c-cac fz16 mt8 uppercase transi-up delay-06">
              {{
                solutionList.length > 0
                  ? solutionList[activeSolution].articleTitleEng
                  : ""
              }}
            </div>
          </div>
          <div class="flex flex-align-end pb30">
            <span class="fz70 c-39e bold lh50">{{ activeSolution + 1 }}</span>
            <span class="fz40 c-cac bold lh30">/{{ solutionList.length }}</span>
          </div>
        </div>
        <div class="w100 flex-wrap mt35 fz16 flex-1 transi-up delay-09">
          {{
            solutionList.length > 0
              ? solutionList[activeSolution].articleIntro
              : ""
          }}
        </div>
        <div class="flex flex-pack-between flex-align-center">
          <div class="flex flex-align-center">
            <div
              class="relative direction-box mr10"
              @mouseenter="showLeftMore = true"
              @mouseleave="showLeftMore = false"
            >
              <img v-lazy="solLeftPic" class="sol-direction-icon pointer" />
              <img
                v-lazy="solLeftActivePic"
                class="sol-direction-active-icon animate__animated pointer"
                :class="
                  showLeftMore == true ? 'animate__fadeIn' : 'animate__fadeOut'
                "
                @click="getLeftMore"
              />
            </div>
            <div
              class="relative direction-box"
              @mouseenter="showRightMore = true"
              @mouseleave="showRightMore = false"
            >
              <img v-lazy="solRightPic" class="sol-direction-icon pointer" />
              <img
                v-lazy="solRightActivePic"
                class="sol-direction-active-icon animate__animated pointer"
                :class="
                  showRightMore == true ? 'animate__fadeIn' : 'animate__fadeOut'
                "
                @click="getRightMore"
              />
            </div>
          </div>
          <div
            class="relative more-btn-box"
            @mouseenter="showSolMore = true"
            @mouseleave="showSolMore = false"
          >
            <div
              class="animate__animated more-btn flex flex-align-center flex-pack-center fz16 pointer"
              v-show="showSolMore !== true"
            >
              了解更多
            </div>
            <router-link
              :to="{
                name: 'solution',
                query: {
                  solId:
                    solutionList.length > 0
                      ? solutionList[activeSolution].channelId
                      : '',
                  name: solutionList.length > 0
                      ? solutionList[activeSolution].articleTitle
                      : '',
                      
                },
              }"
              target="_blank"
            >
              <div
                class="animate__animated more-btn-active flex flex-align-center flex-pack-center c-white fz16 pointer"
                :class="
                  showSolMore == true ? 'animate__fadeIn' : 'animate__fadeOut'
                "
              >
                了解更多
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 项目案例 -->
    <div class="case-box flex-v flex-align-center">
      <div class="fz32 bold mt42 z0">项目案例</div>
      <div class="fz16 mt8 uppercase c-cac z0">our cases</div>
      <div class="z0 color-line mt8"></div>
      <div class="flex w100 flex-pack-center flex-align-center mt20">
        <div
          class="relative case-direction mr30"
          @mouseenter="showCaseLeft = true"
          @mouseleave="showCaseLeft = false"
        >
          <img class="case-direction-icon" v-lazy="caseLeftPic" />
          <img
            v-lazy="caseLeftActivePic"
            class="case-direction-icon case-direction-icon-active animate__animated pointer"
            :class="
              showCaseLeft == true ? 'animate__fadeIn' : 'animate__fadeOut'
            "
            @click="getLeftCase"
          />
        </div>
        <div class="case-list-box flex flex-align-center mr30">
          <div class="case-list-box-wrapper flex flex-align-center">
            <div
              class="animate__animated pointer flex-v pointer"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <router-link
                :to="{
                  name: 'caseCenter',
                  query: {
                    caseId: item.articleId,
                    name: item.articleTitle,
                  },
                }"
                target="_blank"
                :class="[
                  index == 2 ? 'case-item' : 'case-item-small',
                  caseLeftFlag
                    ? 'case-in-left'
                    : caseRightFlag
                    ? 'case-in-right'
                    : '',
                  index == caseList.length - 1 ? '' : 'mr30',
                ]"
              >
                <img
                  :src="item.coverUrl + item.coverPath + item.articleCover"
                  class="case-img"
                  :class="caseRightFlag || caseLeftFlag ? 'pic-fade-in' : ''"
                />
                <div class="flex-v case-content w100">
                  <div
                    class="bold max-line2"
                    :class="index == 2 ? 'fz22 ml2-fz22' : 'fz18 ml2-fz18'"
                  >
                    {{ item.articleTitle }}
                  </div>
                  <div
                    class="max-line3"
                    :class="
                      index == 2
                        ? 'ignore-fz14 ignore-mt30 ignore-ml3-fz14'
                        : 'ignore-fz12 ignore-mt20 ignore-ml3-fz14'
                    "
                  >
                    {{ item.articleIntro }}
                  </div>
                  <div class="flex flex-align-end flex-pack-between">
                    <div class="flex bold flex-align-end">
                      <span
                        class="c-39e"
                        :class="index == 2 ? 'fz40 mt20' : 'fz32 mt10'"
                        >{{ item.month }}</span
                      >
                      <span
                        class="c-999 mb6"
                        :class="index == 2 ? 'fz20' : 'fz16'"
                        >/{{ item.day }}</span
                      >
                    </div>
                    <div class="flex flex-align-center mb4">
                      <img class="eye-img" v-lazy="watchPic" />
                      <span class="fz12 c-999">{{ item.pv }}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div
          class="relative case-direction"
          @mouseenter="showCaseRight = true"
          @mouseleave="showCaseRight = false"
        >
          <img class="case-direction-icon" v-lazy="caseRightPic" />
          <img
            v-lazy="caseRightActivePic"
            class="case-direction-icon case-direction-icon-active animate__animated pointer"
            :class="
              showCaseRight == true ? 'animate__fadeIn' : 'animate__fadeOut'
            "
            @click="getRightCase"
          />
        </div>
      </div>
    </div>
    <!-- 新闻分类 -->
    <div
      class="product-bg-box flex-v flex-align-center"
      ref="newsBgBox"
      :class="showNewsType ? '' : 'no-display'"
    >
      <img v-lazy="productBg" class="product-bg" :style="newsBgScroll" />
      <div class="fz32 bold mt42 z0">最新动态</div>
      <div class="fz16 mt8 uppercase c-cac z0">Our news</div>
      <div class="z0 color-line mt8"></div>
      <div class="flex w100 flex-nowrap z0 mt62 type-list">
        <div
          class="flex-v flex-1 flex-align-center type-item pointer"
          v-for="(item, index) in newsTypeList"
          :key="index"
          @mouseenter="item.mouse = true"
          @mouseleave="item.mouse = false"
          @click="activeNewsType = item"
        >
          <img
            :src="activeBg"
            class="active-bg"
            :class="
              activeNewsType.channelId == item.channelId
                ? 'active-bg-show'
                : item.mouse == true
                ? 'active-bg-show'
                : item.mouse == false
                ? 'active-bg-none'
                : 'active-bg-no'
            "
          />

          <div class="fz18 mt5 transi-up delay-03">{{ item.channelName }}</div>
          <div class="fz50 mt10 bold transi-up delay-06">
            {{ item.cChildNum }}
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻列表 -->
    <div
      class="news-box flex-v flex-align-center"
      ref="newsBox"
      :class="showNews ? '' : 'no-display'"
    >
      <div class="flex">
        <div
          class="news-item flex-v"
          v-for="(item, index) in activeNewsType.cArticles"
          :key="index"
        >
          <router-link
            :to="{
              name: 'newsCenter',
              query: {
                typeId: activeNewsType.channelId,
                articleId: item.articleId,
                name: item.articleTitle,
              },
            }"
            target="_blank"
          >
            <img
              v-lazy="
                item.articleCover
                  ? item.coverUrl + item.coverPath + item.articleCover
                  : newsDefault
              "
              class="news-icon"
            />
            <div class="flex-v news-content flex-1">
              <div class="fz22 bold max-line2 ml2-fz22 transi-up delay-03">
                {{ item.articleTitle }}
              </div>
              <div
                class="ignore-fz14 max-line2 ignore-ml2-fz14 mt24 transi-up delay-06"
              >
                {{ item.articleIntro }}
              </div>
              <div class="flex flex-1 w100"></div>
              <div
                class="flex flex-align-center flex-pack-between mt20 transi-up delay-09"
              >
                <div class="fz20 c-999 bold">
                  {{ item.ptime }}
                </div>
                <div class="flex flex-align-center">
                  <img v-lazy="watchPic" class="watch-icon" />
                  <span class="c-999">{{ item.pv }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="mt30 relative more-btn-box"
        @mouseenter="showNewsMore = true"
        @mouseleave="showNewsMore = false"
      >
        <div
          class="animate__animated more-btn flex flex-align-center flex-pack-center fz16 pointer"
          v-show="showNewsMore !== true"
        >
          更 多
        </div>
        <router-link
          :to="{
            name: 'newsCenter',
            query: {
              typeId: activeNewsType.channelId,
              name: activeNewsType.channelName,
            },
          }"
          target="_blank"
        >
          <div
            class="animate__animated more-btn-active flex flex-align-center flex-pack-center c-white fz16 pointer"
            :class="
              showNewsMore == true ? 'animate__fadeIn' : 'animate__fadeOut'
            "
          >
            更 多
          </div>
        </router-link>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner-box flex-v flex-align-center">
      <div class="fz32 bold mt42 z0">合作伙伴</div>
      <div class="fz16 mt8 uppercase c-cac z0">OUR partnerS</div>
      <div class="z0 color-line mt8"></div>
      <div class="flex-v flex-align-center w100 relative mt35">
        <img class="partner-bg-left" v-lazy="whiteLeftPic" />
        <img class="partner-bg-right" v-lazy="whiteRightPic" />
        <marqueeX
          :send-val="item"
          :pauseFlag="pauseFlag"
          v-for="(item, index) in partnerList"
          :key="index"
        />
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="contact-big-box flex flex-align-center">
      <div class="flex-v flex-1 borderR h100 flex-align-center">
        <div class="fz32 bold mt42 z0">关于我们</div>
        <div class="fz16 mt8 uppercase c-cac z0">ABOUT US</div>
        <div class="z0 color-line mt8"></div>
        <div class="about-content w100">
          {{companyDesc.articleIntro}}
        </div>
        <div
          class="mt30 relative more-btn-box mb20"
          @mouseenter="showContactMore = true"
          @mouseleave="showContactMore = false"
        >
          <div
            class="animate__animated more-btn flex flex-align-center flex-pack-center fz16 pointer"
            v-show="showContactMore !== true"
          >
            更 多
          </div>
          <router-link :to="{ name: 'about' }" target="_blank">
            <div
              class="animate__animated more-btn-active flex flex-align-center flex-pack-center c-white fz16 pointer"
              :class="
                showContactMore == true ? 'animate__fadeIn' : 'animate__fadeOut'
              "
            >
              更 多
            </div>
          </router-link>
        </div>
      </div>
      <div class="flex-v flex-1 borderR h100 flex-align-center">
        <div class="fz32 bold mt42 z0">联系我们</div>
        <div class="fz16 mt8 uppercase c-cac z0">CONTACT US</div>
        <div class="z0 color-line mt8"></div>
        <div class="contact-box flex-v">
          <div class="flex flex-align-center w100 mb37">
            <img class="contact-icon" v-lazy="phonePic" />
            <div class="fz18">业务咨询：{{ companyInfo.telPhone }}</div>
          </div>
          <div class="flex flex-align-center w100 mb37">
            <img class="contact-icon" v-lazy="mobilePic" />
            <div class="fz18">技术服务：{{ companyInfo.mobilePhone }}</div>
          </div>
          <div class="flex flex-align-center w100 mb37">
            <img class="contact-icon" v-lazy="mailPic" />
            <div class="fz18">电子邮箱：{{ companyInfo.email }}</div>
          </div>
          <div class="flex flex-align-center w100 mb37">
            <img class="contact-icon" v-lazy="addrPic" />
            <div class="fz18">{{ companyInfo.companyAddr }}</div>
          </div>
        </div>
      </div>
      <div class="map-box" ref="myMap"></div>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", function () {
  window.scrollTo(0, 0);
});
// @ is an alias to /src
import banner1 from "@/assets/home/banner1.jpg";
import banner2 from "@/assets/home/banner2.jpg";
import banner3 from "@/assets/home/banner3.jpg";

import proIcon1 from "@/assets/home/pro-icon1.png";
import proIcon2 from "@/assets/home/pro-icon2.png";
import proIcon3 from "@/assets/home/pro-icon3.png";
import proIcon4 from "@/assets/home/pro-icon4.png";
import proBg1 from "@/assets/home/pro-bg1.png";
import proBg2 from "@/assets/home/pro-bg2.png";
import proBg3 from "@/assets/home/pro-bg3.png";
import proBg4 from "@/assets/home/pro-bg4.png";
import productBg from "@/assets/home/product-bg.jpg";
import activeBg from "@/assets/home/active-bg.png";
import detailPic from "@/assets/home/detail.png";
import solLeftPic from "@/assets/home/sol-left.png";
import solRightPic from "@/assets/home/sol-right.png";
import solLeftActivePic from "@/assets/home/sol-left-active.png";
import solRightActivePic from "@/assets/home/sol-right-active.png";
import watchPic from "@/assets/home/watch.png";
import whiteLeftPic from "@/assets/home/white-left.png";
import whiteRightPic from "@/assets/home/white-right.png";
import marqueeX from "@/components/common/marqueeX.vue";
import caseLeftPic from "@/assets/home/case-left.png";
import caseRightPic from "@/assets/home/case-right.png";
import caseLeftActivePic from "@/assets/home/case-left-active.png";
import caseRightActivePic from "@/assets/home/case-right-active.png";
import phonePic from "@/assets/home/phone.png";
import mobilePic from "@/assets/home/mobile.png";
import mailPic from "@/assets/home/mail.png";
import addrPic from "@/assets/home/addr.png";
import mapIcon from "@/assets/home/map-icon.png";
import newsDefault from "@/assets/news/icon.jpg";

newsDefault;
import {
  getHomeProduct,
  getHomeNews,
  getHomeCase,
  getHomePartner,
  getHomeSolution,
  getHomeContact,
  getCompanyInfo
} from "@/api/home";

export default {
  name: "Home",
  components: {
    marqueeX,
  },
  data() {
    return {
      newsDefault,
      productBg,
      activeBg,
      detailPic,
      solLeftPic,
      solRightPic,
      solLeftActivePic,
      solRightActivePic,
      watchPic,
      whiteLeftPic,
      whiteRightPic,
      caseLeftPic,
      caseRightPic,
      caseLeftActivePic,
      caseRightActivePic,
      phonePic,
      mobilePic,
      mailPic,
      addrPic,
      bannerList: [banner1, banner2, banner3],
      produceList: [
        {
          icon: proIcon1,
          title: "精准化感知设备",
          eng: "Precision sensing devices",
          desc:
            "十年磨一剑，\n\r团队精心打造的硬件产品在技术含量、\n\r工艺水平和加工制造能力方面\n\r都具备国内先进水平。",
          mouse: -1,
          bg: proBg1,
          path: "productCenter",
        },
        {
          icon: proIcon2,
          title: "提升综合收益",
          eng: "Enhance comprehensive income",
          desc:
            "全面提升农业农村生产\n\r智能化、经营网络化、管理高效化、服务便捷化水平，\n\r用数字化引领驱动农业农村现代化。",
          mouse: -1,
          bg: proBg2,
          path: "solution",
        },
        {
          icon: proIcon3,
          title: "紧跟时代需求",
          eng: "Keep up with The Times",
          desc:
            "客观面对政策变化和时代需求，\n\r紧密跟进农业农村一线，\n\r构筑支撑高端引领的先发优势。",
          mouse: -1,
          bg: proBg3,
          path: "newsCenter",
        },
        {
          icon: proIcon4,
          title: "精细化贴心服务",
          eng: "Keep up with The Times",
          desc:
            "以精心服务换来客户的坚守，\n\r7*24小时全天候为客户提供应急响应服务。",
          mouse: -1,
          bg: proBg4,
          path: "about",
        },
      ],
      productBgScroll: {
        transform: "translate(0, 0)",
      },
      productTypeList: [],
      activeProType: {},
      showIntroduce: false,
      showProType: false,
      showProMore: false,
      activeSolution: 0,
      oldSolution: 0,
      solutionList: [],
      showSolMore: false,
      showLeftMore: false,
      showRightMore: false,
      solPicDirection: -1, //-1无 left：true right：false
      showSolution: false,
      showNewsType: false,
      newsBgScroll: {
        transform: "translate(0, 0)",
      },
      newsTypeList: [],
      activeNewsType: 1,
      showNews: false,
      showNewsMore: false,
      pauseFlag: false,
      showCaseLeft: false,
      showCaseRight: false,
      caseSourceList: [],
      caseIndex: 0,
      caseList: [],
      caseLeftFlag: false,
      caseRightFlag: false,
      showContactMore: false,
      map: null,
      companyInfo: {},
      partnerList: [],
      companyDesc: {}
    };
  },
  methods: {
    productScroll() {
      this.productBgScroll.transform =
        "translate(0, " +
        this.$refs.productBgBox.getBoundingClientRect().top / 3 +
        "px)";
      if (this.$refs.introduceBox.getBoundingClientRect().top < 980) {
        this.showIntroduce = true;
      }
      if (this.$refs.productBgBox.getBoundingClientRect().top < 980) {
        this.showProType = true;
      }
      if (this.$refs.solutionBox.getBoundingClientRect().top < 980) {
        this.showSolution = true;
      }
      if (this.$refs.newsBgBox.getBoundingClientRect().top < 980) {
        this.showNewsType = true;
      }
      if (this.$refs.newsBox.getBoundingClientRect().top < 980) {
        this.showNews = true;
      }
      this.newsBgScroll.transform =
        "translate(0, " +
        this.$refs.newsBgBox.getBoundingClientRect().top / 3 +
        "px)";
    },
    getRightMore() {
      if (this.solPicDirection == -1) {
        this.oldSolution = this.activeSolution;
        if (this.activeSolution != this.solutionList.length - 1) {
          this.activeSolution = this.activeSolution + 1;
        } else {
          this.activeSolution = 0;
        }
        this.solPicDirection = false;
        this.showSolution = false;

        setTimeout(() => {
          this.updateDir();
          this.oldSolution = this.activeSolution;
        }, 1000);
        setTimeout(() => {
          this.updateContent();
        }, 300);
      }
    },
    getLeftMore() {
      if (this.solPicDirection == -1) {
        this.showSolution = false;
        this.oldSolution = this.activeSolution;
        if (this.activeSolution != 0) {
          this.activeSolution = this.activeSolution - 1;
        } else {
          this.activeSolution = this.solutionList.length - 1;
        }
        this.solPicDirection = true;
        setTimeout(() => {
          this.updateDir();
          this.oldSolution = this.activeSolution;
        }, 1000);
        setTimeout(() => {
          this.updateContent();
        }, 300);
      }
    },
    updatePause(val) {
      this.pauseFlag = val;
    },
    updateDir() {
      this.solPicDirection = -1;
    },
    updateContent() {
      this.showSolution = true;
    },
    getLeftCase() {
      if (!this.caseLeftFlag) {
        this.caseLeftFlag = true;
        setTimeout(() => {
          this.getLeftList();
          this.caseLeftFlag = false;
        }, 500);
      }
    },
    getLeftList() {
      if (this.caseIndex == 0) {
        this.caseIndex = this.caseSourceList.length - 1;
      } else {
        this.caseIndex = this.caseIndex - 1;
      }
      this.getCaseList();
    },
    getRightCase() {
      if (!this.caseRightFlag) {
        this.caseRightFlag = true;
        setTimeout(() => {
          this.getRightList();
          this.caseRightFlag = false;
        }, 500);
      }
    },
    getRightList() {
      if (this.caseIndex == this.caseSourceList.length - 1) {
        this.caseIndex = 0;
      } else {
        this.caseIndex = this.caseIndex + 1;
      }
      this.getCaseList();
    },
    getCaseList() {
      let index = this.caseIndex;
      let caseList = this.caseSourceList.slice(index, index + 5);
      if (caseList.length < 5) {
        caseList = caseList.concat(
          this.caseSourceList.slice(0, 5 - caseList.length)
        );
      }
      this.caseList = caseList;
    },
    initMap() {
      let map = new BMap.Map(this.$refs.myMap, {
        // enableMapClick: false,
        minZoom: 4,
        maxZoom: 17,
      });
      map.enableScrollWheelZoom(true); //启用滚轮缩放
      let point = new BMap.Point(104.117119, 30.627565);
      let icon = new BMap.Icon(mapIcon, new BMap.Size(18, 25), {
        anchor: new BMap.Size(14, 14),
      });
      let marker = new window.BMap.Marker(point, { icon: icon }); // 创建标注
      map.centerAndZoom(point, 20);
      map.addOverlay(marker); // 将标注添加到地图中
      //marker.enableDragging();   //可拖拽;
      var opts = {
        width: 160, // 信息窗口宽度
        height: 60, // 信息窗口高度
        title: "成都世纪锐通科技有限公司", // 信息窗口标题
        message: "成都世纪锐通科技有限公司",
      };
      var infoWindow = new BMap.InfoWindow(
        "地址：四川省成都市锦江区静居寺路20号附101号",
        opts
      ); // 创建信息窗口对象
      map.openInfoWindow(infoWindow, point); //开启信息窗口
      this.map = map;
    },
    //根据接口获取初始数据
    initData() {
      // 获取产品列表
      getHomeProduct().then((res) => {
        let list = res;
        list.forEach((item) => {
          item.mouse = -1;
          item.cProductBanks = item.cProductBanks.slice(0, 8);
          // item.cProductBanks.forEach((pro) => {
          //   if (pro.proIntro && pro.proIntro.length > 8) {
          //     pro.proIntro = pro.proIntro.substring(0, 8);
          //   }
          // });
        });
        this.productTypeList = list;
        this.activeProType = list[0];
      });
      // 获取新闻列表
      getHomeNews().then((res) => {
        let list = res;
        list.forEach((item) => {
          item.mouse = -1;
          item.cArticles = item.cArticles.slice(0, 4);
        });
        this.newsTypeList = list;
        this.activeNewsType = list[0];
      });
      // 获取案例列表
      getHomeCase().then((res) => {
        let list = res.objList;
        list.forEach((item, index) => {
          let date = item.ptime.split("-");
          item.index = index + 1;
          item.month = date[1];
          item.day = date[0];
        });
        this.caseSourceList = list;
        this.getLeftCase();
      });
      // 获取合作伙伴列表
      getHomePartner().then((res) => {
        let list = [];
        res = res.slice(0, 20);
        res.forEach((item) => {
          list.push({
            icon: modeUrlObj.imgURL + item.logoPath + item.siteLogo,
            activeIcon: modeUrlObj.imgURL + item.logoPath + item.siteLogo2,
            mouse: false,
          });
        });
        this.partnerList = this.chunkArray(list, 5);
      });
      getHomeSolution().then((res) => {
        this.solutionList = res;
      });
      getHomeContact().then((res) => {
        this.companyInfo = res;
      });
      getCompanyInfo().then((res) => {
        this.companyDesc = res;
      });
    },
    tarnslateDate(date) {
      let nowdate = new Date(date).toLocaleDateString();
      nowdate = nowdate.substring(2);
      return nowdate;
    },
    chunkArray(array, size) {
      //获取数组的长度，如果你传入的不是数组，那么获取到的就是undefined
      const length = array.length;
      //判断不是数组，或者size没有设置，size小于1，就返回空数组
      if (!length || !size || size < 1) {
        return [];
      }
      //核心部分
      let index = 0; //用来表示切割元素的范围start
      let resIndex = 0; //用来递增表示输出数组的下标

      //根据length和size算出输出数组的长度，并且创建它。
      let result = new Array(Math.ceil(length / size));
      //进行循环
      while (index < length) {
        //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
        result[resIndex++] = array.slice(index, (index += size));
      }
      //输出新数组
      return result;
    },
    myBrowser() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
      var isIE =
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera; //判断是否IE浏览器
      var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
      var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
      var isSafari =
        userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
      var isChrome =
        userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
      if (isIE) {
        return "IE";
      }
      if (isOpera) {
        return "Opera";
      }
      if (isEdge) {
        return "Edge";
      }
      if (isFF) {
        return "FF";
      }
      if (isSafari) {
        return "Safari";
      }
      if (isChrome) {
        return "Chrome";
      }
    },
  },
  destroyed() {
    // if (this.myBrowser() == "FF") {
    //   window.removeEventListener("DOMMouseScroll", this.productScroll);
    // } else {
    //   window.removeEventListener("scroll", this.productScroll);
    // }
    window.removeEventListener("scroll", this.productScroll);
  },
  mounted() {
    this.initData();
    // console.log(this.myBrowser());
    window.addEventListener("scroll", this.productScroll);
    // if (this.myBrowser() == "FF") {
    //   window.addEventListener("DOMMouseScroll", this.productScroll);
    // } else {
    //   window.addEventListener("scroll", this.productScroll);
    // }
    this.initMap();
  },
  activated() {
    this.produceList.forEach((item) => {
      item.mouse = -1;
    });
    this.showIntroduce = false;
    this.showProType = false;
    this.showSolution = false;
    this.showNewsType = false;
    this.showNews = false;
    this.showProMore = false;
    this.showContactMore = false;
    this.showNewsMore = false;
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  overflow-x: hidden;
  a {
    color: #5b5b5b;
  }
}
.block-banner {
  ::v-deep .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    margin: 0;
    height: 600px !important;
    background: #e2e2e2;
  }
  ::v-deep .el-carousel__item {
    height: 600px !important;
    img {
      width: 100%;
      height: 600px !important;
    }
  }
  ::v-deep .el-carousel__container {
    height: 600px !important;
  }
  ::v-deep .el-carousel__indicator--horizontal {
    padding: 20px 4px;
  }
  ::v-deep .el-carousel__indicator--horizontal {
    padding: 20px 4px;
  }
  ::v-deep .el-carousel__button {
    width: 60px;
    height: 6px;
  }
  ::v-deep .is-active > .el-carousel__button {
    background-color: #39e274 !important;
  }
}

.pro-detail-icon{
  width: 80px;
  height: 80px;
}
.pro-item {
  width: 480px;
  height: 560px;
  background: #fff;
  padding: 50px;
  position: relative;
  .pro-bg {
    width: 428px;
    height: 434px;
    // opacity: 0.6;
    position: absolute;
    bottom: -20px;
    right: -123px;
  }
  .pro-icon {
    width: 140px;
    height: 140px;
    margin-top: 3px;
    margin-bottom: 25px;
  }

  .more-btn {
    width: 150px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #2a3038;
    border-radius: 25px;
    position: absolute;
    bottom: 39px;
    left: 115px;
    line-height: 50px;
    color: #475669;
    text-align: center;
  }
  .more-active {
    width: 150px;
    height: 50px;
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border-radius: 25px;
    position: absolute;
    bottom: 39px;
    left: 115px;
    line-height: 50px;
    border: none;
    color: #ffffff;
    text-align: center;
  }
}
.pro-item:nth-child(2n) {
  background: #f8f8f8;
}
.mtm70 {
  margin-top: -110px;
}
.bg-white {
  background: #f4fff8 !important;
}
.type-list {
  height: 135px;
}
.product-bg-box {
  height: 320px;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  .product-bg {
    width: 100%;
    height: 568px;
    position: absolute;
    left: 0;
    bottom: -200px;
    z-index: 0;
  }
  .type-item {
    height: 133px;
    position: relative;
    .active-bg {
      position: absolute;
      top: 0;
      height: 100%;
      margin: 0 auto; /*水平居中*/
      left: 0; /*此处不能省略，且为0*/
      right: 0; /*此处不能省略，且为0*/
    }
  }
}
.product-box {
  width: 100%;
  padding: 42px 186px 50px 186px;
  background: #f8f8f8;
  .product-item {
    width: 380px;
    height: 420px;
    position: relative;
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
    .pro-item-bg {
      width: 379px;
      height: 419px;
      background: #39e274;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      transition: all 1s;
      .detailPic {
        height: 80px;
        width: 80px;
      }
    }
    .pro-item-icon {
      height: 280px;
      max-width: 280px;
    }
  }
  .product-item:nth-child(-n + 4) {
    border-top: 1px solid #e2e2e2;
  }
  .product-item:nth-child(2n) {
    background-color: #f8f8f8;
  }
  .product-item:nth-child(2n-1) {
    background-color: #fff;
  }
  .product-item:nth-child(5) {
    background-color: #f8f8f8;
  }
  .product-item:nth-child(7) {
    background-color: #f8f8f8;
  }
  .product-item:nth-child(6) {
    background-color: #fff;
  }
  .product-item:nth-child(8) {
    background-color: #fff;
  }
  .product-item:nth-child(4n) {
    border-right: 1px solid #e2e2e2;
  }
  .product-item:last-child {
    border-right: 1px solid #e2e2e2;
  }
  .product-item:hover {
    .pro-item-bg-active {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      transition: all 1s;
    }
  }
}
.more-btn-box {
  width: 150px;
  height: 50px;
  .more-btn {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: #ffffff;
    border: 1px solid #2a3038;
    position: absolute;
    left: 0;
    top: 0;
  }
  .more-btn-active {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none !important;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.solution-box {
  width: 100%;
  height: 500px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-bottom: none;
  position: relative;
  .solution-icon {
    width: 50%;
    height: 499px;
  }
  .old-solution-icon {
    width: 50%;
    height: 499px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .solution-content {
    padding: 50px 70px 57px 95px;
    height: 100%;
    .direction-box {
      height: 30px;
      width: 30px;
      .sol-direction-icon {
        height: 30px;
        width: 30px;
        position: absolute;
        left: 0;
        right: 0;
      }
      .sol-direction-active-icon {
        height: 30px;
        width: 30px;
        position: absolute;
        left: 0;
        right: 0;
        box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
      }
    }
  }
}
.news-box {
  width: 100%;
  padding: 40px 75px 50px 75px;

  background: #f8f8f8;
  .news-item {
    width: 420px;
    height: 500px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    // box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
    margin-right: 30px;
    cursor: pointer;
    .news-icon {
      width: 418px;
      height: 252px;
    }
    .news-content {
      width: 100%;
      padding: 28px 34px 26px 34px;
      .watch-icon {
        width: 25px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }
  .news-item:last-child {
    margin-right: 0;
  }
  .news-item:hover {
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
  }
}

.no-display {
  .transi-up {
    opacity: 0;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: all 0s;
  }
  .active-bg-show {
    width: 0;
    animation: none;
  }
}
.transi-up {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 0.7s;
}
.delay-03 {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.delay-06 {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.delay-09 {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.active-bg-show {
  width: 100%;
  animation: moveShow 0.7s;
}
.active-bg-none {
  width: 0px;
  animation: moveNone 0.7s;
}
.active-bg-no {
  width: 0px;
}
@keyframes moveShow {
  0% {
    width: 10px;
  }
  100% {
    width: 100%;
  }
}
@keyframes moveNone {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
@keyframes moreHover {
  0% {
    background: #ffffff;
    border: 1px solid #e2e2e2;
  }
  100% {
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none;
  }
}
@keyframes moreNormal {
  0% {
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none;
  }
  100% {
    background: #ffffff;
    border: 1px solid #e2e2e2;
  }
}
.partner-box {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  padding: 50px 269px 80px 280px;
  .partner-bg-left {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    z-index: 1;
  }
  .partner-bg-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    z-index: 1;
  }
}

.case-box {
  padding: 0px 0px 44px 0px;
  background: #f8f8f8;
  .case-direction {
    height: 80px;
    width: 80px;

    .case-direction-icon {
      height: 80px;
      width: 80px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .case-direction-icon-active {
      box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    }
  }
  .case-item-small {
    width: 336px;
    height: 500px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    .case-img {
      width: 336px;
      height: 251px;
    }
    .eye-img {
      width: 20px;
      height: 14px;
      margin-right: 7px;
    }
  }
  .case-item {
    width: 420px;
    height: 600px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.1);
    .case-img {
      width: 420px;
      height: 302px;
    }
    .eye-img {
      width: 25px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .case-content-small {
    padding: 21px 27px;
  }
  .case-content {
    padding: 30px 34px 26px 34px;
  }
  .case-list-box {
    width: 1182px;
    height: 602px;
    overflow: hidden;
    padding-top: -50px;
    .case-list-box-wrapper {
      height: 602px;
      margin-left: -350px;
    }
  }
}
.case-in-left {
  transform: translateX(366px);
  transition: transform 0.5s ease-in-out;
}
.case-in-right {
  transform: translateX(-366px);
  transition: transform 0.5s ease-in-out;
}
.case-normal {
  transform: translateX(0px);
  transition: transform 0s;
}
.contact-big-box {
  width: 100%;
  min-height: 548px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  .borderR {
    border-right: 1px solid #e2e2e2;
  }
  .map-box {
    width: 635px;
    height: 548px;
  }
  .about-content {
    padding: 35px 48px 0 51px;
    line-height: 1.6em;
    text-indent: 2em;
  }
  .contact-box {
    padding: 60px 0px 0 0;
    .contact-icon {
      height: 48px;
      width: 48px;
      margin-right: 21px;
    }
  }
}
@keyframes picFadeIn {
  0% {
    opacity: 1;
  } /*初始状态 透明度为0*/
  100% {
    opacity: 0;
  } /*结束状态 透明度为1*/
}
@-webkit-keyframes picFadeIn {
  /*针对webkit内核*/
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.pic-fade-in {
  animation: picFadeIn; /*动画名称*/
  animation-duration: 1.5s; /*动画持续时间*/
  -webkit-animation: picFadeIn 1.5s; /*针对webkit内核*/
}
</style>
<!-- <style scoped>
@media (max-width: 1366px) {
  .case-content .ignore-mt30 {
    margin-top: 20px;
  }
  .case-content .ignore-mt20 {
    margin-top: 10px;
  }
  .news-content .ignore-fz14 {
    font-size: 12px;
  }
  .news-content .ignore-fz14 {
    font-size: 12px;
  }
  .news-content .ignore-ml2-fz14 {
    max-height: 34px !important;
  }
}
</style> -->